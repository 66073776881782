import { FormProvider, useForm } from "recalculate-form";
import joinClassNames from "classnames";

import accountAPI from "api/account";

import { openAlert } from "components/dialog";

import ResendButton from "./components/resend-button";
import VerifyButton from "./components/verify-button";
import VerifyCodeInput from "./components/verify-code-input";

import { getErrorType, getFieldError } from "../../duck/selectors";
import { useConnect } from "./duck/hooks";

import signinModalClasses from "../../styles/classes.module.scss";
import classes from "./styles/classes.module.scss";

interface Props {
  nextStep: VoidFunction;
}

const VerifyCode = ({ nextStep }: Props) => {
  const form = useForm<{ code: string }>({ defaultValues: { code: "" } });
  const { verifyAuthCode } = useConnect();
  const email = accountAPI.codeTimes.value?.email;

  const submitHandler = async ({
    values: { code },
  }: {
    values: { code: string };
  }) => {
    form.resetErrors();
    try {
      await verifyAuthCode(email!, code).unwrap();

      nextStep();
    } catch (error) {
      if (getErrorType(error)) {
        form.setErrors({
          code: getFieldError(error as Error, "nonFieldErrors"),
        });
      } else {
        openAlert({ message: "An error occurred while sending the code" });
      }
    }
  };

  return (
    <FormProvider form={form}>
      <form
        className={joinClassNames(
          signinModalClasses.contentForm,
          classes.contentForm,
        )}
        onSubmit={form.handleSubmit(submitHandler)}
      >
        <div className={classes.textWrapper}>
          <h2 className={signinModalClasses.gradientTitle}>
            Enter verification code
          </h2>
          <p className={signinModalClasses.description}>
            Please enter the verification code sent to{" "}
            <span className={classes.email}>{email}</span>
          </p>
        </div>
        <div className={classes.codeWrapper}>
          <VerifyCodeInput
            onFilled={() => form.handleSubmit(submitHandler)()}
          />
          <ResendButton email={email!} />
        </div>
        <VerifyButton />
      </form>
    </FormProvider>
  );
};

export default VerifyCode;
